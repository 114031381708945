*,
*::after,
*::before {
	box-sizing: border-box;
}

:root {
	font-size: 15px;
	margin: 0;
	position: relative;
	--color-text: #fff;
	--color-text-alt: #787878;
	--color-bg: #000;
	--color-link: #fff;
	--color-link-hover: #ccc;
	--page-padding: 2rem;

	/* Stack and slideshow */
	--stack-margin: 10rem;
	--slide-gap: 2vh;
	--stack-width: 39vw;
	--stack-item-height: 8vh;
	--slide-width: 40vw;
	--slide-height: 50vh;
}
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
    display: none;
}

body {
	margin: 0;
	position: relative;
	color: var(--color-text);
	background-color: var(--color-bg);
	font-family: acumin-pro, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	/* -ms-overflow-style: none;  IE and Edge */
    /* scrollbar-width: none;  Firefox */
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
	content: '';
	position: fixed;
	z-index: 1000;
}

.js .loading::before {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/* background: var(--color-bg); */
	background: #c87e4f;
}

.js .loading::after {
	top: 50%;
	left: 50%;
	width: 60px;
	height: 60px;
	margin: -30px 0 0 -30px;
	border-radius: 50%;
	opacity: 0.4;
	background: var(--color-link);
	animation: loaderAnim 0.7s linear infinite alternate forwards;

}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.5,0.5,1);
	}
}

a {
	text-decoration: none;
	color: var(--color-link);
	outline: none;
}

a:hover {
	color: var(--color-link-hover);
	outline: none;
}

/* Better focus styles from https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
a:focus {
	/* Provide a fallback style for browsers
	 that don't support :focus-visible */
	outline: none;
	background: lightgrey;
}

a:focus:not(:focus-visible) {
	/* Remove the focus indicator on mouse-focus for browsers
	 that do support :focus-visible */
	background: transparent;
}

a:focus-visible {
	/* Draw a very noticeable focus style for
	 keyboard-focus on browsers that do support
	 :focus-visible */
	outline: 2px solid red;
	background: transparent;
}

.oh {
	position: relative;
	overflow: hidden;
}

.oh__inner {
	display: inline-block;
	will-change: transform;
}

.unbutton {
	background: none;
	border: 0;
	padding: 0;
	margin: 0;
	color: inherit;
	font: inherit;
	font-size: medium;
}

.unbutton:focus {
	outline: none;
}

.frame {
	padding: 1.5rem 5vw 0;
	z-index: 100;
	text-align: center;
	position: relative;
	position: absolute;
	width: 100%;
}

/* .frame__title {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
} */

.frame__title {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    position: absolute;
    bottom: 0;
    /* left: var(--page-padding); */
    width: calc(100% - var(--page-padding) * 2);
}

.frame__title-main {
	font-size: 1.5rem;
	margin: 0;
	font-weight: normal;
}

/* .frame__title-back {
	position: relative;
	display: flex;
	align-items: flex-end;
} */

.frame__title-back {
    display: flex;
    align-items: flex-end;
    margin-right: 1rem; /* Adjust margin as needed */
}

.frame__title-back span {
	display: none;
}

.frame__title-back svg {
	fill: #fff;
}

.frame__title-prev {
	width: 100%;
}

.frame__logo {
	line-height: 0.9;
}

.frame__logo-title {
	font-family: wayfinder-cf, serif;
	font-weight: 100;
	font-style: normal;
	font-size: 2.75rem;
	margin: 0;
}

.frame__logo-subtitle {
	margin-left: 0.75rem;
}

.stack-wrap {
	width: 100%;
	position: absolute;
    top: 0;
}

.stack {
	margin: 0 auto;
	width: var(--stack-width);
	min-width: 300px;
	grid-gap: 2vh;
	display: grid;
	place-items: center;
}

.stack__item {
	opacity: 0.2;
	width: 100%;
	height: var(--stack-item-height);
	background-size: cover;
	background-position: center;
	cursor: pointer;
	will-change: width, height, transform, opacity;
	transition: opacity 0.3s;
}

.stack__item:hover {
	opacity: 0.5;
}

.stack__item--empty {
	pointer-events: none;
	height: var(--stack-margin);
}

.slides {
	width: 100%;
	position: relative;
	background: var(--color-bg);
}

.slides .stack {
	width: 100%;
	margin-left: auto;
	margin-right: var(--slide-gap);
}

.slides .stack__item {
	pointer-events: none;
	opacity: 0.5;
	height: var(--slide-height);
}

.slides .stack__item--current {
	opacity: 0.6;
}

.title {
	position: fixed;
	bottom: 0;
	left: 50vw;
	margin-bottom: 15vh;
	pointer-events: none;
}

.title__main {
	font-size: 11vw;
	margin: 0;
	font-family: wayfinder-cf, serif;
	font-weight: 100;
	line-height: 1;
}

.title__sub {
	display: block;
	margin-top: -1.5vw;
	line-height: 1;
	font-size: clamp(1rem,2.5vw,2.5rem);
	margin-left: 0.25vw;
	font-weight: 200;
}

.content {
	position: fixed;
	pointer-events: none;
	top: 0;
	left: 0;
	z-index: 100;
	display: grid;
	height: 100vh;
	width: 100%;
	padding: var(--page-padding);
	grid-template-areas: '...' 'back' 'slides' '...';
	grid-template-columns: 1fr;
	grid-template-rows: auto auto 1fr auto;
	grid-gap: var(--slide-gap);
}

.content__item {
	grid-area: slides;
	overflow: hidden;
	position: absolute;
	height: 0;
	opacity: 0;
	pointer-events: none;
}

.content__item--current {
	overflow: visible;
	position: relative;
	pointer-events: auto;
	height: auto;
	opacity: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.content__item-title {
	font-family: wayfinder-cf, serif;
	font-weight: 100;
	line-height: 1;
	font-size: clamp(2rem,9vw,10rem);
	margin: 0 0 0.5rem 0;
	color: #c87e4f;
}

.content__item-title > span {
	display: block;
	white-space: nowrap;
	line-height: 1;
}

.content__item-title > span:not(:first-child) {
	margin-top: -1vw;
}

.content__back {
	grid-area: back;
	display: flex;
	align-items: center;
	cursor: pointer;
	opacity: 0;
	pointer-events: none;
}

.content__back svg {
	fill: #fff;
	margin-right: 0.5rem;
}

.content__nav-wrap {
	grid-area: 1 / 1 / -1 / -1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.content__nav {
	z-index: 100;
	cursor: pointer;
	opacity: 0;
	flex: none;
	align-self: center;
	pointer-events: none;
	height: calc((100vh - var(--slide-height) - var(--slide-gap) * 4) / 3);
}

.content__nav svg {
	stroke: #fff;
	stroke-width: 3px;
    width: auto;
    height: 100%;
    opacity: 0.5;
    transition: opacity 0.3s;
}

.content__nav:hover svg,
.content__nav:focus-visible svg {
	opacity: 1;
}

.content__nav--next svg {
	transform: scale(-1);
}

.content--open .content__back,
.content--open .content__nav {
	opacity: 1;
	pointer-events: auto;
}

@media screen and (min-width: 53em) {
	:root {
		--stack-margin: 50vh;
	}
	.frame {
		text-align: left;
		top: 0;
		left: 0;
		display: grid;
		align-content: space-between;
		max-width: none;
		position: fixed;
		height: 100vh;
		z-index: 1000;
		padding: var(--page-padding);
		pointer-events: none;
		grid-template-columns: auto auto 1fr;
		grid-template-rows: auto auto auto;
		grid-template-areas: 'logo ... sponsor'
							'... ...  ...'
							'title title ...';
	}
	.frame__title {
		grid-area: title;
		justify-content: flex-start;
		color: var(--color-text-alt);
	}
	.frame__logo {
		grid-area: logo;
	}
	.frame a {
		pointer-events: auto;
	}
	.content {
		grid-template-areas: 'back slides'
							'title slides'	
							'... slides';
		grid-template-columns: 1fr 50vw;
		grid-template-rows: auto 1fr 10vh;
		grid-column-gap: var(--page-padding);
		padding: var(--page-padding) var(--slide-gap) var(--page-padding) var(--page-padding);
	}
	.content__item {
		grid-area: title;
		align-self: end;
		text-align: left;
	}
	.content__item-title {
		display: inline-flex;
		flex-direction: column;
	}
	.content__item-title > span:first-child {
		padding-right: 2rem;
	}
	.content__item-title > span:last-child {
		margin-left: auto;
	}
	.content__item-description {
		max-width: 300px;
	}
	.content__back {
		grid-area: back;
		justify-self: end;
	}
	.content__nav-wrap {
		grid-area: slides; 
	}
	.slides {
		background: transparent;
		width: 50vw;
		margin-left: auto;
		margin-right: var(--slide-gap);
	}
	.slides .stack__item--current {
		opacity: 1;
	}
}
